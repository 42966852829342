import { Container, Typography } from '@mui/material'
import { useTranslation } from '@pastellink/i18n-react'
import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { BasePage } from '../components'

const Content = styled(Container)`
  max-height: 100vh;
  overflow: auto;
`

const Line = styled(Typography)`
  line-height: 2;
`

export const PrivacyPolicyPage = memo(() => {
  const { t } = useTranslation()
  const lines = useMemo(() => t('privacyPolicy.content').split('\n'), [t])
  const supplementLines = useMemo(
    () => t('privacyPolicy.supplementContent').split('\n'),
    [t],
  )

  return (
    <BasePage>
      <Content>
        <Typography variant="h2">{t('privacyPolicy.title')}</Typography>
        {lines.map((line, idx) => (
          <Line
            key={`${idx}`}
            variant={!isNaN(parseInt(line.trim()[0])) ? 'h5' : 'body1'}
          >
            {line}
          </Line>
        ))}
        <Typography variant="h2">
          {t('privacyPolicy.supplementTitle')}
        </Typography>
        {supplementLines.map((line, idx) => (
          <Line
            key={`${idx}`}
            variant={!isNaN(parseInt(line.trim()[0])) ? 'h5' : 'body1'}
          >
            {line}
          </Line>
        ))}
      </Content>
    </BasePage>
  )
})

PrivacyPolicyPage.displayName = 'PrivacyPolicyPage'
