import { PropsWithChildren } from 'react'
import styled from 'styled-components'

type Props = {
  scrollable?: boolean
}

const Container = styled.div<{ scrollable?: boolean }>`
  height: ${({ scrollable }) => (scrollable ? undefined : '100vh')};
  background-color: ${({ theme }) => theme.colors.background};
`

export function BasePage({ children, scrollable }: PropsWithChildren<Props>) {
  return <Container scrollable={scrollable}>{children}</Container>
}
