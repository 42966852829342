export const privacyPolicy = {
  title: 'Privacy Policy',
  content: `Thank you for considering using "Island Together". We take privacy very seriously, and we want you to feel confident in how we handle your personal information. This privacy policy explains how we collect, use, and protect your information when you use our app.

  1. Information We Collect
  When you use our app, we may collect the following information:
  - Personal Information: We may collect personal information that you provide to us, such as your name, email address.
  - Device Information: We may collect information about the device you use to access our app, such as the model, operating system, and unique device identifiers.
  - Usage Information: We may collect information about how you use our app, such as the features you use and the pages you visit.
  
  2. Use of Information
  We use the information we collect to:
  - Provide and improve our app: We use your information to provide and improve our app, including to personalize your experience and to troubleshoot technical issues.
  - Communicate with you: We may use your information to communicate with you about our app and related products or services.
  - Protect our users: We may use your information to protect the security and integrity of our app and to prevent fraud or other illegal activities.
  
  3. Sharing of Information
  We may share your information with third parties in the following circumstances:
  - With your consent: We may share your information with third parties if you give us your consent to do so.
  - To our service providers: We may share your information with third-party service providers who help us to operate and improve our app.
  - For legal reasons: We may share your information with third parties if we are required to do so by law or if we believe that such disclosure is necessary to protect our rights or the rights of others.
  
  4. Security of Information
  We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no data transmission over the internet or electronic storage system can be guaranteed to be 100% secure.
  
  5. Changes to This Policy
  We may update this privacy policy from time to time. If we make any material changes, we will notify you by posting the updated policy on our website or by sending you an email.
  
  6. Contact Us
  If you have any questions or concerns about this privacy policy or our app, please contact us at 63146208.
  
  By using our app, you agree to the terms of this privacy policy. If you do not agree with any terms of this policy, please do not use our app.  
`,
  supplementTitle: '隱私政策',
  supplementContent: `感謝您使用Island Together。我們非常重視隱私，希望您對我們處理您的個人信息感到自信。本隱私政策解釋了當您使用我們的應用程式時，我們如何收集、使用和保護您的信息。

1. 我們收集的信息
當您使用我們的應用程式時，我們可能會收集以下信息：
- 個人信息：我們可能會收集您向我們提供的個人信息，例如您的姓名、電子郵件地址。
- 設備信息：我們可能會收集您用來訪問我們的應用程式的設備信息，例如型號、操作系統和唯一設備識別符。
- 使用信息：我們可能會收集有關您使用我們的應用程式的信息，例如您使用的功能和訪問的頁面。

2. 信息的使用
我們使用我們收集的信息來：
- 提供和改善我們的應用程式：我們使用您的信息來提供和改善我們的應用程式，包括個性化您的體驗和解決技術問題。
- 與您溝通：我們可能會使用您的信息與您溝通，關於我們的應用程式和相關產品或服務。
- 保護我們的用戶：我們可能會使用您的信息來保護我們應用程式的安全和完整性，防止欺詐或其他非法活動。

3. 信息的分享
我們可能會在以下情況下與第三方分享您的信息：
- 得到您的同意：如果您給予我們同意，我們可能會與第三方分享您的信息。
- 向服務提供商分享：我們可能會將您的信息與第三方服務提供商分享，以幫助我們運營和改善我們的應用程式。
- 基於法律原因分享：如果我們被法律要求這樣做，或者我們認為這樣的披露是為了保護我們或他人的權利，我們可能會與第三方分享您的信息。

4. 信息的安全
我們採取合理措施來保護您的信息免受未經授權的訪問、披露、更改或破壞。然而，因特網上的數據傳輸或電子存儲系統不能保證100%安全。

5. 本政策的更改
我們可能會不時更新本隱私政策。如果我們進行任何實質性的更改，我們將通過在我們的網站上發布更新的政策或發送電子郵件通知您。

6. 聯繫我們
如果您對本隱私政策或我們的應用程式有任何問題或疑慮，請聯繫我們，電話號碼為 63146208。

當您使用我們的應用程式時，即表示您同意本隱私政策的條款。如果您不同意本政策的任何條款，請勿使用我們的應用程式。
  
`,
}
