export const riddleTutorial = {
  map: '地圖',
  next: '下一步',
  gameStart: '開始遊戲',
  tutorial2Content: `玩家可以隨時打開地圖\n查看地點`,
  tutorial3Title: '遊戲包操作須知',
  tutorial3Content: `在遊戲開始之前請確保已完成\n以下準備工作！`,
  tutorial3Option1Label: '查看一次遊戲包',
  tutorial3Option2Label: '細閲《遊戲手冊》',
  tutorial4Title: '教學完成',
  tutorial4Content: `看來你已經準備好了\n集合所有隊友，一起展開旅程！`,
}
