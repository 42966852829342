import { useMemo } from 'react'
import { RouteObject } from 'react-router-dom'
import { routes } from './routes'

export function useRoute(): RouteObject[] {
  const permittedRoutes = useMemo(
    () => routes.map((route) => ({ ...route, element: route.element })),
    [],
  )

  return permittedRoutes
}
