export const qrCodeScanner = {
  noPermission: {
    content: 'Allow the app to scan QR code with the camera.',
    openSettings: 'Open Settings',
    title: 'The app Would Like to Access the Camera',
  },
  noPermissionPlaceholder: 'No camera permission',
  invalidCode: {
    title: 'Invalid QR code',
    content: 'The QR code cannot be recognized, please scan again',
  },
  codeInUse: {
    title: 'QR code Redeemed',
    content:
      'This game kit serial has already been redeemed and should not be used again',
  },
  duplicatedCharacter: {
    title: 'Duplicated Character',
    content: 'Player needs to select different character',
  },
}
