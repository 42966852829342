export const home = {
  version: '{{env}} Version {{version}}',
  enterButton: {
    title: '進入',
    subTitle: 'ENTER',
  },
  riddle: {
    title: '解謎遊戲',
    subTitle: '3 RIDDLES',
  },
}
