import {
  ThemeProvider as MuiThemeProvider,
  THEME_ID,
} from '@mui/material/styles'
import {
  AppTheme,
  ThemeProvider as PLThemeProvider,
} from '@pastellink/theme-provider'
import { PropsWithChildren, memo } from 'react'
import { useMuiTheme } from './use-mui-theme'

type Props = PropsWithChildren<{ theme: AppTheme }>

export const ThemeProvider = memo<Props>(({ theme, children }) => {
  const muiTheme = useMuiTheme(theme)

  return (
    <PLThemeProvider theme={theme}>
      <MuiThemeProvider theme={{ [THEME_ID]: muiTheme }}>
        {children}
      </MuiThemeProvider>
    </PLThemeProvider>
  )
})

ThemeProvider.displayName = 'ThemeProvider'
