import { colors } from './colors'

export const theme = { colors }
export * from './theme-provider'

type AppColorTheme = (typeof theme)['colors']

declare module '@pastellink/theme-provider' {
  interface ColorTheme extends AppColorTheme {}
}
