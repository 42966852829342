import { common } from './common'
import { mainMenu } from './main-menu'
import { home } from './home'
import { permission } from './permission'
import { privacyPolicy } from './privacy-policy'
import { qrCodeScanner } from './qr-code-scanner'
import { riddleTutorial } from './riddle-tutorial'

export const en = {
  common,
  qrCodeScanner,
  riddleTutorial,
  permission,
  privacyPolicy,
  mainMenu,
  home,

  continue: 'Continue',
  start: 'Start',
  scan: 'Scan',
  riddle: {
    beforeStartContent: `Trainee gods "Dumb" and "Bell" were sent to Cheung Chau for a quest. On the first day of registration, there was an argument on the spot, and it turned out to be a family feud between two buns...
    In this game, you will look back at the past of the island, immerse yourself in the the present, and imagine what Cheung Chau will be like in the future. `,
    scannerModal: {
      title: 'Riddle kit',
      content:
        'This game needs to be experienced with a physical riddle kit. If you have not purchased it, welcome to buy it at Cheung Chau Cinema. If you have already purchased, please scan the QR Code below to start the game.',
      note: 'Note: This game is only available in Traditional Chinese.',
      badge: 'Traditional Chinese only',
    },
    start: 'Start',
    hint: {
      title: 'Use Hint',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      hint: 'Hint',
      useHint: 'Get Hint',
      showAnswer: 'Reveal Answer',
      returnButton: 'Return',
    },
    input: {
      title: 'Input Answer',
      numericSubTitle: '({{number}} Numbers)',
      submitButton: 'Submit',
      retryButton: 'Retry',
      correctAnswer: 'Correct',
      wrongAnswer: 'Wrong',
    },
    trivia: 'Trivia!',
    completed: 'Completed',
    thanksParticipating: 'Thanks for participating',
    ensureGameQuality:
      'To ensure game quality, please do not tell others the end~',
  },
  route: {
    title: 'Game Route',
  },
  assemble: {
    title: 'Assemble',
    waiting: 'Waiting to partner...',
    assembled: 'Assembled!',
    overrideButton: 'I have arrived',
  },
  'image-recognition': {
    start: 'Start Mission',
    instruction: 'Click object of this shape to complete the mission',
    completed: 'Found! This is one of the material to build Bao Shan',
    item: 'Item {{index}}:',
    videoInstruction: 'Please point the camera at the map',
    videoDialog: {
      title: 'Finale',
      description: 'Click to view the finale',
    },
    itemCount: '({{count}}/{{total}})',
  },
  setting: {
    title: 'Settings',
    menu: {
      passwordSetting: 'Password Settings',
      termsAndConditions: 'Terms & Conditions',
    },
    editProfile: 'Edit Profile',
  },
  'character-selection': {
    character: 'Character',
    'no-character': 'No Character',
    'select-character': 'Please Select Character',
    enter: 'Enter',
  },
  aboutUs: {
    title: 'ABOUT US',
    name: 'Cheung Chau Cinema',
    content: `
    Established since 1931, The Cheung Chau Cinema is classified as a Grade III historic building. With the revitalization of the traditional cultural hub, it awakens and once again rises its silver screens after 30 years.

    The screen is a window through time and space, walking in, feeling the past and exploring the future.

    The Cheung Chau Cinema is one of the only two pre-war cinemas left in Hong Kong, with great historical value, thus the conservation of the main building is the key essence. Not only is the cinema a venue for entertainment, it is also a collective memory and even a life hub for most of the Cheung Chau residents. With its profound cultural heritage, the Cheung Chau Cinema focuses on cultural inheritance, promoting intangible cultural heritage through workshops, film sharing, and Linkage to the Metaverse; allowing locals and even foreign visitors to experience the traditional culture of Cheung Chau and Hong Kong.
    `,
  },
  history: {
    title: 'HISTORY',
    games: 'Games',
    vol1: 'Vol. 1',
    vol2: 'Vol. 2',
    comingSoon: 'Coming Soon',
  },
  characters: {
    pleaseSelectCharacter: 'Please select character',
    duplicatedCharacter: 'Duplicated character',
    duplicatedCharacterMessage: 'Please select different character',
    selectAgain: 'Select again',
  },
  'user-profile': {
    addProfilePicture: 'Add profile picture',
    userName: '用户名稱 User Name',
    uploadAvatar: {
      error: {
        title: 'Failed to upload Profile Picture',
      },
    },
    loadSystemParameter: {
      error: {
        title: 'Error',
        content:
          'Unable to fetch required information, please try again later.',
      },
    },
  },
  editProfile: {
    username: 'User Name',
    email: 'Email',
  },
}
