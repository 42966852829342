import { Navigate, RouteObject } from 'react-router-dom'
import { HealthCheckPage, PrivacyPolicyPage } from '../pages'

type RouteItem = RouteObject
// | (Omit<RouteObject, 'children'> & {
//     children?: RouteObject['children'] // | RouteItem[]
//   })

export const routes: RouteItem[] = [
  { path: 'health', element: <HealthCheckPage /> },
  { path: 'privacy-policy', element: <PrivacyPolicyPage /> },
  { path: '*', element: <Navigate to="/privacy-policy" replace /> },
]
