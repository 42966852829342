import { createTheme } from '@mui/material'
import { AppTheme } from '@pastellink/theme-provider'
import { useMemo } from 'react'

export function useMuiTheme(theme: AppTheme) {
  return useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main: theme.colors.primary,
          },
          secondary: {
            main: theme.colors.secondary,
          },
        },
      }),
    [theme],
  )
}
