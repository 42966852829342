export const home = {
  version: '{{env}} 版本 {{version}}',
  enterButton: {
    title: '進入',
    subTitle: 'ENTER',
  },
  riddle: {
    title: '解謎遊戲',
    subTitle: '3 RIDDLES',
  },
  cheungChau: {
    title: '長洲',
    subTitle: 'CHEUNG CHAU',
  },
}
