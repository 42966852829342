export const riddleTutorial = {
  map: 'Map',
  next: 'Next',
  gameStart: 'Game start',
  tutorial2Content: `You can open map at any time\nView places`,
  tutorial3Title: 'Game kit notes',
  tutorial3Content: `Please ensure following tasks are completed\nbefore game start!`,
  tutorial3Option1Label: 'View once game kit',
  tutorial3Option2Label: 'Read "Game Guild"',
  tutorial4Title: 'Tutorial completed',
  tutorial4Content: `Looks like you are ready\nGroup your friends, begin the trip together!`,
}
