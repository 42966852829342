import {
  Alert,
  AlertProps as MuiAlertProps,
  Snackbar as MuiSnackbar,
} from '@mui/material'
import { memo } from 'react'

export type SnackbarProps = Pick<MuiAlertProps, 'severity'> & {
  visible: boolean
  content: string
  onDismiss(): void
}

export const Snackbar = memo<SnackbarProps>(
  ({ severity, content, onDismiss, visible }) => {
    return (
      <MuiSnackbar
        open={visible}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={onDismiss}
      >
        <Alert severity={severity} variant="filled">
          {content}
        </Alert>
      </MuiSnackbar>
    )
  },
)

Snackbar.displayName = 'Snackbar'
