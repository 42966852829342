export const common = {
  add: '加入',
  back: '返回',
  cancel: '取消',
  change: '更改',
  clear: '清除',
  clickToRetry: '重試',
  confirm: '確定',
  continue: '繼續',
  delete: '刪除',
  discard: '丟棄',
  edit: '更改',
  empty: '沒有內容',
  emptyList: '此列表沒有內容',
  errorMessage: '系統錯誤。{{userDependantServiceDeskRetry}} \n{{error}}',
  format: {
    date: 'DD/MM/YYYY',
    datetime: 'DD/MM/YYYY HH:mm',
    kendoDateInput: 'dd/MM/yyyy',
  },
  from: '由',
  login: '登入',
  logout: '登出',
  signUp: '註冊',
  next: '下一個',
  no: '否',
  noRecordsAvailable: '找不到記錄',
  obsolete: '失效',
  ok: '確定',
  reset: '重設',
  retry: '重試',
  save: '儲存',
  search: '搜尋',
  submit: '提交',
  submitted: '已提交',
  to: '到',
  unrecognizedErrorTitle: '錯誤',
  unrecognizedErrorTitleWithErrorCodes: '錯誤 - {{errorMessage}}',
  version: '版本 {{version}} / {{codePushVersion}}',
  view: '查看',
  yes: '是',
}
