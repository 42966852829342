export const qrCodeScanner = {
  noPermission: {
    content: '允許應用程式使用相機掃描二維碼。',
    openSettings: '打開設置',
    title: '應用程式想要使用設備的鏡頭權限',
  },
  noPermissionPlaceholder: '沒有設備的鏡頭權限',
  invalidCode: {
    title: '二維碼錯誤',
    content: '二維碼不能被認證，請重新掃描',
  },
  codeInUse: {
    title: '二維碼已被使用',
    content: '此遊戲工具包已被使用',
  },
  duplicatedCharacter: {
    title: '角色重複',
    content: '玩家們需選擇不同角色！',
  },
}
