import { memo, useMemo } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import styled from 'styled-components'
import { useRoute } from './routers'
import { env } from './utilities'

type Props = {}

const Container = styled.div`
  overscroll-behavior: none;
`

export const Content = memo<Props>(() => {
  const routeItems = useRoute()

  const router = useMemo(
    () => createBrowserRouter(routeItems, { basename: env.basePath }),
    [routeItems],
  )

  return (
    <Container>
      <RouterProvider router={router} />
    </Container>
  )
})

Content.displayName = 'Content'
