import { memo, PropsWithChildren } from 'react'
import styled from 'styled-components'

type Props = PropsWithChildren<{}>

const Modal = styled.div`
  display: flex;
  background-color: ${({ theme }) =>
    theme.colors.palettes.general.greyTransparent};

  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding: ${({ theme }) => theme.sizes.spacings[5]}px;

  align-items: center;
  justify-content: center;
  z-index: 100000;
`

export const OverlayModal = memo<Props>(({ children }) => (
  <Modal>{children}</Modal>
))

OverlayModal.displayName = 'OverlayModal'
