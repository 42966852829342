import { languagePack } from '@ccc-island-together/language-pack'
import { initI18n } from '@pastellink/i18n-react'

const DEFAULT_LANGUAGE = 'zh-Hant'
export type InitResult = {}

export async function init(): Promise<InitResult> {
  await initI18n(
    { [DEFAULT_LANGUAGE]: languagePack[DEFAULT_LANGUAGE] },
    DEFAULT_LANGUAGE,
  )
  return {}
}

type Resources = (typeof languagePack)['zh-Hant']

declare module '@pastellink/i18n-react' {
  interface AppResources extends Resources {}
}
