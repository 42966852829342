import { OverlayProvider } from '@pastellink/overlay-provider'
import { memo, PropsWithChildren } from 'react'
import { ErrorBoundary, OverlaySpinner } from './components'
import { Content } from './content'
import { useInit } from './init'
import { theme, ThemeProvider } from './theme'

const InitContainer = memo<PropsWithChildren<{}>>(({ children }) => {
  const initResult = useInit()

  if (initResult == null) return null // TODO

  return <>{children}</>
})

InitContainer.displayName = 'InitContainer'

export function App() {
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <OverlayProvider SpinnerComponent={OverlaySpinner}>
          <InitContainer>
            <Content />
          </InitContainer>
        </OverlayProvider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}
