import { common } from './common'
import { mainMenu } from './main-menu'
import { home } from './home'
import { permission } from './permission'
import { privacyPolicy } from './privacy-policy'
import { qrCodeScanner } from './qr-code-scanner'
import { riddleTutorial } from './riddle-tutorial'

export const zhHant = {
  common,
  qrCodeScanner,
  riddleTutorial,
  permission,
  privacyPolicy,
  mainMenu,
  home,

  continue: '繼續',
  start: '開始',
  scan: '掃描',
  riddle: {
    beforeStartContent: `你將化身為見習神明，\n從不一樣的角度回看島上的過去，\n感受你身處的現在，以及想像長洲今後的所往。\n見習神明「阿抌」和「鍾Bell」被派到長洲考核，\n就在報到的第一天，當地發生了一場口角，\n吵架的竟是兩個平安包！`,
    scannerModal: {
      title: '遊戲工具包',
      content: `此遊戲需要配合實體工具包進行體驗。\n如未購買，歡迎到長洲戲院購買。如已購買，請掃下方QR Code開始遊戲。`,
      note: '請注意：此遊戲只提供繁體中文版本',
      badge: '中文版限定',
    },
    start: '開始解謎',
    hint: {
      title: '使用提示',
      content:
        '如覺得謎題太困難，可以按畫面上的「使用提示」。提示分為「初階提示」、「進階提示」和「揭曉答案」。若想獲得「進階提示」或「揭曉答案」。\n在獲得「進階提示」或「揭曉答案」之前，請先嘗試回答。',
      hint: '提示',
      useHint: '獲得提示',
      showAnswer: '揭曉答案',
      returnButton: '繼續作答',
    },
    input: {
      title: '請輸入答案',
      numericSubTitle: '({{number}}個數目字)',
      submitButton: '送出答案',
      retryButton: '重新作答',
      correctAnswer: '答案正確',
      wrongAnswer: '答案錯誤',
    },
    trivia: '小知識！',
    completed: '成功過關',
    thanksParticipating: '感謝参與',
    ensureGameQuality: '為確保遊戲品質，切記不要劇透～',
  },
  route: {
    title: '遊戲路線',
  },
  assemble: {
    title: '集合隊友',
    waiting: '等待隊友...',
    assembled: '全隊集齊！',
    overrideButton: '我已到達',
  },
  'image-recognition': {
    start: '開始任務',
    'instruction-recognition': '留意四周，把相機對準這個形狀的物件，便可過關。',
    'instruction-360': '點擊這個形狀的物件，便可完成任務。',
    completed: '找到啦！這就是建造包山的材料其中之一',
    item: '物件{{index}}:',
    videoInstruction: '請把相機對準掃描地圖',
    videoDialog: {
      title: '最終回',
      description: '點擊觀看結局',
    },
    itemCount: '({{count}}/{{total}})',
  },
  setting: {
    title: '設定',
    menu: {
      passwordSetting: '密碼設置',
      termsAndConditions: '條款及細則',
    },
    editProfile: '編輯資料',
  },
  'character-selection': {
    character: '角色',
    'no-character': '沒有角色',
    'select-character': '請選擇角色',
    enter: '確定',
  },
  aboutUs: {
    title: '關於我們',
    name: '長洲戲院',
    content: `
    長洲戲院於 1931 年落成，被列為三級歷史建築。 於 1997 年停業近 30 年後，長洲戲院重新以傳統文化基地之名，再次拉起銀幕。

    「銀幕是時空的窗口，走進來，感受過去，發現未來。」

    長洲戲院是香港兩所碩果僅存的戰前戲院之一，極具歷史價值，保育戲院主樓是長洲戲院活化項目中的重要一環。長洲戲院滿載著洲民的回憶，這裡除了是娛樂場所，更是生活的交匯點，藉著這深厚文化底蘊，長洲戲院將以傳承文化為核心，透過工作坊、電影分享和元宇宙連結，推廣非物質文化遺產，讓本地甚至是外國旅客體驗長洲和香港的傳統文化。  
    `,
  },
  history: {
    title: '遊戲歷史',
    games: '所有遊戲',
    vol1: '第一版',
    vol2: '第二版',
    comingSoon: '敬請期待',
  },
  characters: {
    pleaseSelectCharacter: '請選擇角色',
    duplicatedCharacter: '角色重複',
    duplicatedCharacterMessage: '玩家們需選擇不同角色',
    selectAgain: '重新選擇',
  },
  'user-profile': {
    addProfilePicture: '新增頭像',
    userName: '用户名稱 User Name',
    uploadAvatar: {
      error: {
        title: '新增頭像失敗',
      },
    },
  },
  editProfile: {
    username: '用戶名稱',
    email: '電郵',
  },
  loadSystemParameter: {
    error: {
      title: '系統異常',
      content: '無法獲取所需資料，請稍候再試',
    },
  },
}
